import React from "react"
import { Helmet } from 'react-helmet'
import { Row, Col, Container } from 'reactstrap'
import { /*Table*/ Image } from 'semantic-ui-react'
import Alton617Cropped from "./resources/images/Alton617Cropped.jpg"
import DQPose from "./resources/images/DQPose.jpg"
//import { Button} from 'reactstrap'
//import ReactPixel from 'react-facebook-pixel';
/**
 * The Tour Date component for the application.
 * Year: 2020
 * Author: Dillan Simpson
 */

//ReactPixel.pageView()
const TourDates = () => {
  return (
    <Container>
      <Helmet>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9714476978963581"
          crossorigin="anonymous"></script>
        <title>TTR Tour Dates</title>
        <meta name="description" content="The Third-Rates Tour Dates" />
      </Helmet>
      <Container>
        <Row>
          <Col>
            <h1 className="tour-h1">Sorry! No Current Tour Dates</h1>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <h3 className="tour-h3-top">Thank you for making our debut tour so special</h3>
            <h3 className="tour-h3">We can't wait to do it again!</h3>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row >
          <Col xl={5}>
            <Image className="figure-img img-fluid img-blur-edge tour-img-bigger" size="massive" src={Alton617Cropped} alt={"Alton,IL 6/17/2022"} />
          </Col>
          <Col xl={7}>
            <Image className="figure-img img-fluid img-blur-edge tour-img-smaller" size="large" src={DQPose} alt={"DQ TTR Pose"} />
          </Col>
        </Row>
      </Container>

    </Container>

    /** Use for Next Tour */
  //   <Table inverted selectable>
  //   <Table.Header>
  //     <Table.Row>
  //         <Table.HeaderCell><h2>Tour Dates</h2></Table.HeaderCell>
  //       <Table.HeaderCell></Table.HeaderCell>
  //       <Table.HeaderCell></Table.HeaderCell>
  //     </Table.Row>
  //   </Table.Header>

  //   <Table.Body>
  //     <Table.Row>
  //         <Table.Cell><h3><strong>June 11 @ 6 PM CDT</strong></h3></Table.Cell>
  //       <Table.Cell><h4>Bandcamp Acoustic Live Stream</h4></Table.Cell>
  //       <Table.Cell textAlign='right'>
  //           <a className="button-link" target="_blank" href="https://thethirdrates.bandcamp.com/merch/the-third-rates-acoustic-live-stream" rel="noopener noreferrer">
  //             <Button size="large" color="primary">Tickets</Button>
  //           </a>
  //       </Table.Cell>
  //     </Table.Row>
  //     <Table.Row>
  //         <Table.Cell><h3><strong>June 12 @ 6 PM CDT</strong></h3></Table.Cell>
  //         <Table.Cell><h4>Bandcamp Live Stream</h4></Table.Cell>
  //         <Table.Cell textAlign='right'>
  //           <a className="button-link" target="_blank" href="https://thethirdrates.bandcamp.com/merch/the-third-rates-live-show" rel="noopener noreferrer">
  //             <Button size="large" color="primary">Tickets</Button>
  //           </a>
  //         </Table.Cell>
  //     </Table.Row>
  //     <Table.Row>
  //         <Table.Cell><h3><strong>June 15</strong></h3></Table.Cell>
  //         <Table.Cell><h4>Broken Clock Brewery</h4> <h6>Minneapolis, MN</h6> With: Runaway Ricochet </Table.Cell>
  //       <Table.Cell textAlign='right'>
  //           <a className="button-link" target="_blank" href="https://www.brokenclockbrew.com/events/" rel="noopener noreferrer">
  //             <Button size="large" color="primary" disabled>Venue</Button>
  //           </a>
  //       </Table.Cell>
  //     </Table.Row>
  //     <Table.Row>
  //       <Table.Cell><h3><strong>June 17</strong></h3></Table.Cell>
  //         <Table.Cell><h4>The Conservatory</h4><h6>Alton, IL</h6> With: Boss Battle, Candylion</Table.Cell>
  //       <Table.Cell textAlign='right'>
  //           <a className="button-link" target="_blank" href="https://theconservatoryalton.com/" rel="noopener noreferrer">
  //             <Button size="large" color="primary">Venue</Button>
  //           </a>
  //       </Table.Cell>
  //     </Table.Row>
  //     <Table.Row>
  //       <Table.Cell><h3><strong>June 19</strong></h3></Table.Cell>
  //         <Table.Cell><h4>Blue Note Lounge</h4> <h6>Oklahoma City, OK</h6> With: The Big News</Table.Cell>
  //       <Table.Cell textAlign='right'>
  //           <a className="button-link" target="_blank" href="https://okcbluenote.com/" rel="noopener noreferrer">
  //           <Button size="large" color="primary">Venue</Button>
  //         </a>
  //       </Table.Cell>
  //     </Table.Row>
  //     <Table.Row>
  //         <Table.Cell><h3><strong>June 20</strong></h3></Table.Cell>
  //         <Table.Cell><h4>Killer's Taco</h4> <h6>Denton, TX</h6> With: Flip and The Combined Effort, Young Costello</Table.Cell>
  //       <Table.Cell textAlign='right'>
  //           <a className="button-link" target="_blank" href="http://www.killerstacos.com/" rel="noopener noreferrer">
  //           <Button size="large" color="primary">Venue</Button>
  //         </a>
  //       </Table.Cell>
  //     </Table.Row>
  //     <Table.Row>
  //       <Table.Cell><h3><strong>June 22</strong></h3></Table.Cell>
  //         <Table.Cell><h4>The Starlighter</h4><h6>San Antonio, TX</h6> With: Young Costello</Table.Cell>
  //       <Table.Cell textAlign='right'>
  //         <a className="button-link" target="_blank" href="https://thestarlighter.com/" rel="noopener noreferrer">
  //           <Button size="large" color="primary">Venue</Button>
  //         </a>
  //       </Table.Cell>
  //     </Table.Row>
  //     <Table.Row>
  //       <Table.Cell><h3><strong>June 23</strong></h3></Table.Cell>
  //         <Table.Cell><h4>Flamingo Cantina</h4> <h6>Austin, TX</h6> With: Young Costello</Table.Cell>
  //       <Table.Cell textAlign='right'>
  //         <a className="button-link" target="_blank" href="https://flamingocantina.com/" rel="noopener noreferrer">
  //           <Button size="large" color="primary">Venue</Button>
  //         </a>
  //       </Table.Cell>
  //     </Table.Row>
  //   </Table.Body>
  // </Table>
  );
}


export default TourDates;