import Navigation from "./Navigation"
import React, { Component } from "react"
import localstorage from "../services/localstorage"
import Footer from "./Footer"
import { Container } from "reactstrap";

/**
 * Layout component.
 * Year: 2020
 * Author: Dillan Simpson
 */
class Layout extends Component {
  render() {
    return (
      <Container>
        <Navigation cart={localstorage.getCart()} />
        <main>{this.props.children}</main>
        <Footer />
      </Container>
    );
  }
}

export default Layout;
