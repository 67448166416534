import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Dillan from "../resources/images/DillanClay.png";
import Cory from "../resources/images/CoryClay.png";
import Levi from "../resources/images/LeviClay.png";
import Connor from "../resources/images/ConnorClay.png";
import Tyler from "../resources/images/TylerClay.png";
import Javier from "../resources/images/JavierClay.png";
import { Container } from "semantic-ui-react";
import { Row } from 'reactstrap'

/**
 * Grid component for The Band page.
 * Year: 2020
 * Author: Dillan Simpson
 */

// Map of images to display
const tileData = [
  { img: Dillan, title: "Dillan", paragraph: "Guitar/Vocals" },
  { img: Cory, title: "Cory", paragraph: "Tbone/Vocals" },
  { img: Levi, title: "Levi", paragraph: "Drums" },
  { img: Connor, title: "Connor", paragraph: "Sax" },
  { img: Javier, title: "Javier", paragraph: "Trombone" },
  { img: Tyler, title: "Tyler", paragraph: "Bass" },
];
// Styles to set on the grid
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: "#282c34",
  },
  gridList: {
    width: "100%",
    height: "100%",
    paddingBottom: "7vh",
  },
}));

// Image grid to display images with a short bio for each band member.
export default function ImgGrid() {
  const classes = useStyles();
  var id = "";
  return (
    <div className={classes.root}>
      <GridList cellHeight={300} className={classes.gridList} cols={1}>
        {tileData.map((tile) => (
          <GridListTile key={tile.img} cols={tile.cols || 1}>
            {/* {tile.title === "Dillan" || tile.title === "Cory"
              ? (id = "smallerImg")
              : (id = "bandImg")} */}
            <Container>
              <Row xs={1}>
                <figure className="figure">
                  <img className="figure-img img-fluid bandImg" src={tile.img} alt={tile.title} id={id} />
                </figure>
              </Row>
            </Container>
            <GridListTileBar id="container2" title={tile.title} />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}
