/**
 * A helper class to combine the printful order data and self ship order data.
 * Year: 2022
 * Author: Dillan Simpson
 */
export default class OrderCombine {
    /**
     * Constructor.
     * @param {object=} orderObj the order data to be combined
     */
    constructor(orderObj) {
        if (orderObj._id)
        {
            this._id = orderObj._id
        }
        else if (this.id)
        {
            this.id = orderObj.id
        }
        this.id = orderObj.id
        this.selfShip = orderObj._id ? orderObj._id : orderObj.id
        this.firstName = orderObj.firstName;
        this.lastName = orderObj.lastName;
        this.email = orderObj.email;
        this.phone = orderObj.phone;
        this.address = orderObj.address;
        this.secondAddress = orderObj.secondAddress;
        this.country = orderObj.country;
        this.city = orderObj.city;
        this.state = orderObj.state;
        this.zip = orderObj.zip;
        this.productId = orderObj.productId;
        this.productName = orderObj.productName;
        this.description = orderObj.description;
        this.retail_price = orderObj.retail_price;
        this.quantity = orderObj.quantity;
        this.cart = orderObj.cart;
    }

    /**
     * Converts body data into a self ship sale request.
     * @param {object=} orderObj the combined order object 
     * @returns a self ship sale body request
     */
    toSsSale(orderObj)
    {       
        let ssBody = 
        {
            prodDbId:  orderObj.prodDbId,
            firstName: orderObj.firstName,
            lastName: orderObj.lastName,
            email: orderObj.email,
            phone: orderObj.phone,
            address: orderObj.address,
            secondAddress: orderObj.secondAddress,
            country: orderObj.country,
            city: orderObj.city,
            state: orderObj.state,
            zip: orderObj.zip,
            productId: orderObj.productId,
            productName: orderObj.productName,
            description: orderObj.description,
            retail_price: orderObj.retail_price,
            ss_shipping: orderObj.ss_shipping,
            quantity: orderObj.quantity
        }

        return ssBody;
    }

    /**
     * Converts body data into a prinful order request.
     * @param {object=} orderObj the combined order object
     * @returns a sprinful order body request
     */
    toPfOrder(orderObj) {
        let PfBody =
        {
            firstName: orderObj.firstName,
            lastName: orderObj.lastName,
            email: orderObj.email,
            phone: orderObj.phone,
            address: orderObj.address,
            secondAddress: orderObj.secondAddress,
            country: orderObj.country,
            city: orderObj.city,
            state: orderObj.state,
            zip: orderObj.zip,
            cart: orderObj.cart,
        }

        return PfBody;
    }

    /**
     * Helper method to determine if the order data contains a self ship data.
     * @param {object=} orderObj the combined order data
     * @returns a boolean
     */
    static isSS(orderObj) {
        return orderObj.productId === 1112 ? true : false;
    }

    /**
     * Helper method to determine if the order data contains a printful data.
     * @param {object=} orderObj the combined order data
     * @returns a boolean
     */
    static isPrintful(orderObj) {
        return orderObj.productId !== 1112 ? true : false;
    }
}