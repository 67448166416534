//import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import currency from 'currency.js'
import Loader from './utils/Loader'
import { Helmet } from 'react-helmet'
//import SWSCD from "../resources/images/SWSCd.png";
//import ReactPixel from 'react-facebook-pixel';

/**
 * The MerchStore component for the application. This will contain the Shopify API calls.
 * Year: 2021
 * Author: Dillan Simpson
 */
const MerchStore = ({ products, ssProducts, cart }) => {
  // const [quantity, setQuantity] = useState(0)
  // const [outOfStock, setOutOfStock] = useState(false)
  // const [localQuantity] = useState(cart.items.filter(item => item._id).length)
  
  // useEffect(() => {
  //   if (ssProducts && ssProducts.length > 0)
  //   {
  //     setQuantity(ssProducts[0].quantity)
  //   }
  //   if ((quantity - localQuantity) <= 0) {
  //     setOutOfStock(true)
  //   }
  //   else
  //   {
  //     setOutOfStock(false)
  //   }
  // }, [quantity, ssProducts])

  return(
    <div className="row justify-content-center">
      <Helmet>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9714476978963581"
          crossorigin="anonymous"></script>
        <title>TTR Merch Store</title>
        <meta name="description" content="The Third-Rates Merch Store" />
      </Helmet>
      <div className="row justify-content-center">
        {
          // !outOfStock && ssProducts && ssProducts.length > 0 ?
          //   ssProducts.map((ssProduct) => {
          //     return (
          //       <div className="col-sm-4 p-2" key={ssProduct._id}>
          //         <figure className="figure store-figure">
          //           <img className="figure-img img-fluid" src={SWSCD} alt="See What Sticks CD" />
          //           <h6 className="ss-prod-shipping"><span>Shipping Only Available For USA</span></h6><br/>
          //           <figcaption className='fig-text'>
          //             <h5>{ssProduct.name}</h5>
          //             <h6>{currency(ssProduct.retail_price).format(true)}</h6>
          //           </figcaption>
          //           <Link className="btn btn-pinkish stretched-link product-link" to={`/ProductSelfShip/${ssProduct._id}`} role="button">View</Link>
          //         </figure>
          //       </div>
          //     )
          //   }) :
          //   ssProducts.map((ssProduct) => {
          //     return (
          //       <div className="col-sm-4 p-2" key={ssProduct._id}>
          //         <figure className="figure store-figure">
          //           <img className="figure-img img-fluid ss-img-out" src={SWSCD} alt="See What Sticks CD" />
          //           <h6 className="ss-prod-out"><span>Sorry We Are Out Of Stock</span></h6><br />
          //           <figcaption className='fig-text'>
          //             <h5>{ssProduct.name}</h5>
          //             <h6>{currency(ssProduct.retail_price).format(true)}</h6>
          //           </figcaption>
          //           {/* <Link className="btn btn-pinkish stretched-link product-link" to={`/ProductSelfShip/${ssProduct._id}`} role="button">View</Link> */}
          //         </figure>
          //       </div>
          //     )
          //   })
        }
        {
          products && products.length > 0 ?
            products.map((product) => {
              return (
                <div className="col-sm-4 p-2" key={product.sync_product.id}>
                  <figure className="figure store-figure">
                    <img className="figure-img img-fluid" src={product.sync_product.thumbnail_url} alt={product.sync_product.name} />
                    <figcaption className='fig-text'>
                      <h5>{product.sync_product.name}</h5>
                      <h6>{currency(product.sync_variants[0].retail_price).format(true)}</h6>
                    </figcaption>
                    <Link className="btn btn-pinkish stretched-link product-link" to={`/product/${product.sync_product.id}`} role="button">View</Link>
                  </figure>
                </div>
              )
            }) :
            <Loader />
        }
      </div>
    </div>
)
}

MerchStore.propTypes = {
  products: PropTypes.array,
  ssProducts: PropTypes.array,
  cart:       PropTypes.array
}

export default MerchStore;
