import React, { useState, useEffect } from 'react'
import { Container } from 'semantic-ui-react'
import { Col, Alert } from 'reactstrap'
import PropTypes from 'prop-types'
import currency from 'currency.js'
import SWSCD from "../resources/images/SWSCd.png"
//import ReactPixel from 'react-facebook-pixel';

/**
 * The ProductSelfShip component for the application. Self shipped product page where customers can add items to cart.
 * This will display self shipped product by id.
 * Year: 2022
 * Author: Dillan Simpson
 */
const ProductSelfShip = ({ ssProduct, addToCart, setCartNotification, cart }) => {
  const [notification, setNotification] = useState(false)
  const [localQuantity, setLocalQuantity] = useState(cart.items.filter(item => item._id).length)
  const [quantity] = useState(ssProduct.quantity)
  const [outOfStock, setOutOfStock] = useState(false)

  useEffect(() => {
    // Check if ssorder or not
    if (localQuantity >= quantity) {
      setOutOfStock(true)
    }
  }, [quantity, localQuantity])

  const submitAddCart = (event) => {
    event.preventDefault()
    addToCart(
      ssProduct
    )
    setLocalQuantity(cart.items.filter(item => item._id).length)
    if (notification) {
      // Dismisses the cart Notification after 1 seconds
      setTimeout(function () {
        setCartNotification([])
      }, 1000);
      setCartNotification([
        `${ssProduct.name} has been added to your cart!`,
        "alert-success",
      ]);
      setNotification(true);
    }
    else {
      setCartNotification([
        `${ssProduct.name} has been added to your cart!`,
        "alert-success",
      ]);
      // Dismisses the cart Notification after 1 seconds
      setTimeout(function () {
        setCartNotification([])
      }, 1000);
      setNotification(true);
    }
  }

  return (
    <div className="row justify-content-center">
      <Container fluid={false} text>
        <Alert className="alert-merch">
          <strong>Shipping Only Available For USA</strong>
        </Alert>
      </Container>
      {
        outOfStock ?
          <Container fluid={false} text>
            <Alert className="alert-out-of-stock" color="danger">
              <strong>Sorry We Are Out Of Stock</strong>
            </Alert>
          </Container>
          :
          <div></div>
      }
      <div className="col-md-6 col-sm-12 p-2">
        <figure className="figure">
          <div className="container">
            <figcaption>
              <h4>{ssProduct.name}</h4>
              <h5>{currency(ssProduct.retail_price).format(true)}</h5>
            </figcaption>
          </div>
          {
          outOfStock ?
              <img className="figure-img img-fluid ss-img-out" src={SWSCD} alt="See What Sticks CD" />
            :
              <img className="figure-img img-fluid" src={SWSCD} alt="See What Sticks CD" />
          }
        </figure>
      </div>

      <Container>
        <Col>
          <form className="justify-content-center" onSubmit={(e) => submitAddCart(e)}>
            <div className="form-group prod-form">
              <button type="submit" className="btn btn-pinkish" disabled={outOfStock}>Add to cart</button>
            </div>
          </form>
        </Col>
      </Container>
    </div>
  )
}

ProductSelfShip.propTypes = {
  ssProduct: PropTypes.object,
  addToCart: PropTypes.func,
  setCartNotification: PropTypes.func,
  cart: PropTypes.object
}

export default ProductSelfShip
