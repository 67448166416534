import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react'
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem
} from 'reactstrap';

/**
 * The Navigation Bar for the application.
 * Year: 2020
 * Author: Dillan Simpson
 */
const Navigation = ({ cart, location }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar expand="lg" variant="dark" dark >
      <Container>
        <NavbarBrand href="/">The Third-Rates</NavbarBrand>
        <NavbarToggler className="px-2" onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink
                href="/theBand"
                exact={true}
                onClick={toggle}
                className="inactive"
              >
                The Band
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/Music"
                exact={true}
                onClick={toggle}
                className="inactive"
              >
                Music
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/TourDates"
                exact={true}
                onClick={toggle}
                className="inactive"
              >
                Tour Dates
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/Store"
                exact={true}
                onClick={toggle}
                className="inactive"
              >
                Merch Store
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <Nav className="px-2" navbar>
          <NavItem>
            <NavLink
              href="/Cart"
              className={`nav-link${location.pathname.includes('/Cart') ? ' active' : ''}`}
              onClick={toggle}>
              <Button animated='vertical'>
                <Button.Content hidden>({cart.items.length})</Button.Content>
                <Button.Content visible>
                  <Icon name='shop' size="large" />
                </Button.Content>
              </Button>
            </NavLink>
          </NavItem>
        </Nav>
      </Container>
    </Navbar>
  );
};

Navigation.propTypes = {
  cart: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(Navigation)
