import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Row, Col } from "reactstrap"
import {
  faTwitter,
  faInstagram,
  faYoutube,
  faFacebook,
  faItunes,
  faSpotify,
  faBandcamp,
  faTwitch
} from "@fortawesome/free-brands-svg-icons"

/**
 * A component to display each each social media and music streaming icon along with the link to them.
 * Year: 2020
 * Author: Dillan Simpson
 */
const Footer = () => {
  return (
    <footer>
      <div className="social-container">
        <Row>
          <Col className="row justify-content-start">
            <p className="copy-right">&copy; 2020 The Third Rates</p>
          </Col>
          <Col className="social-links" >
            <a className="twitter social" target="_blank" href="https://twitter.com/TheThirdRates" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} size="1x" />
            </a>
            <a className="instagram social" target="_blank" href="https://www.instagram.com/thethirdrates" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="1x" />
            </a>
            <a className="spotify social" target="_blank" href="https://open.spotify.com/artist/4wEmo0fVzIbQD3QG9AYd7r?si=e8-CIQaLQGKUJaveqDaMag" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSpotify} size="1x" />
            </a>
            <a className="itunes social" target="_blank" href="https://music.apple.com/us/artist/the-third-rates/1524884647/" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faItunes} size="1x" />
            </a>
            <a className="band social" target="_blank" href="https://thethirdrates.bandcamp.com/" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faBandcamp} size="1x" />
            </a>
            <a className="youtube social" target="_blank" href="https://www.youtube.com/channel/UClEbO5h5kCdsE0QGzPo3C6A" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} size="1x" />
            </a>
            <a className="twitch social" target="_blank" href="https://www.twitch.tv/thethirdrates" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitch} size="1x" />
            </a>
            <a className="facebook social" target="_blank" href="https://www.facebook.com/thethirdrates" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="1x" />
            </a>
          </Col>
        </Row>
      </div>
    </footer>
  );
}

export default Footer;