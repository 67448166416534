import React, { useEffect, useState } from "react";
import { Button, Icon, Container } from "semantic-ui-react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import currency from "currency.js";
import ProductVariants from "./printfulVariants";
import CarouselPage from "../components/utils/CarouselPage";
//import ReactPixel from 'react-facebook-pixel';

/**
 * The Product component for the application. Product page where customers can add items to cart.
 * This will display product by id.
 * Year: 2022
 * Author: Dillan Simpson
 */
const Product = ({ product, addToCart, setCartNotification }) => {
  const [variant, setVariant] = useState(product.sync_variants[0].id);
  const [variantDetails] = useState(
    ProductVariants.products.find(
      (prod) => prod.productId === product.sync_product.id
    ).variants
  );
  const [variObj, setVariObj] = useState(
    variantDetails.find(
      (vari) =>
        vari.productId === product.sync_product.id && vari.id === variant
    )
  );
  const [size, setSize] = useState(variObj.size);
  const [color, setColor] = useState(variObj.className);
  const [image, setImage] = useState(product.sync_product.thumbnail_url);
  const [notification, setNotification] = useState(false);

  const photoArr = [
    { prodId: 324500935 },
    { prodId: 324501353 },
    { prodId: 324501180 },
    { prodId: 324501256 },
  ];

  const submitAddCart = (event) => {
    event.preventDefault();
    addToCart(product.sync_variants.find((vari) => vari.id === variant));

    //   ReactPixel.trackCustom('merchOrder', 'product',  // begin parameter object data
    //     {
    //       value: currency(variant.retail_price).format(true),
    //       currency: 'USD',
    //       contents: [
    //         {
    //           id: variant.id,
    //           quantity: 1
    //         }],
    //       content_type: 'product'
    //     }
    // // end parameter object data
    // );
    //    ReactPixel.fbq('merchOrder', 'productPage', { item: variObj.name });

    if (notification) {
      // Dismisses the cart Notification after 1 seconds
      setTimeout(function () {
        setCartNotification([]);
      }, 1000);
      setCartNotification([
        `${variObj.name} has been added to your cart!`,
        "alert-success",
      ]);
      setNotification(true);
    } else {
      setCartNotification([
        `${variObj.name} has been added to your cart!`,
        "alert-success",
      ]);
      // Dismisses the cart Notification after 1 seconds
      setTimeout(function () {
        setCartNotification([]);
      }, 1000);
      setNotification(true);
    }
  };

  useEffect(() => {
    const vari = variantDetails.find(
      (vari) => vari.size === size && vari.className === color
    );
    setVariant(vari.id);
    setVariObj(vari);
    vari.image
      ? setImage(vari.image)
      : setImage(product.sync_product.thumbnail_url);
  }, [color, product.sync_product.thumbnail_url, size, variantDetails]);

  return (
    <div className="row justify-content-center">
      <div className="col-md-6 col-sm-12 p-2">
        <figure className="figure">
          <div className="container">
            <figcaption>
              <h4>{product.sync_product.name}</h4>
              <h5>
                {variant
                  ? currency(
                      product.sync_variants.find((vari) => vari.id === variant)
                        .retail_price
                    ).format(true)
                  : "..."}
              </h5>
            </figcaption>
          </div>
          {photoArr.find((prod) => prod.prodId === product.sync_product.id) !=
          null ? (
            <CarouselPage productId={product.sync_product.id} />
          ) : (
            <img
              className="figure-img img-fluid"
              src={`${image}`}
              alt={product.name}
            />
          )}
        </figure>
      </div>

      <figure className="figure">
        <div className="justify-content-start">
          <Row>
            <Col>
              <Button.Group>
                {variObj
                  ? variObj.sizes.map((size2) => (
                      <Button
                        className={
                          size === size2
                            ? "product-size-active"
                            : "product-size"
                        }
                        onClick={() => setSize(size2)}
                      >
                        {size2}{" "}
                      </Button>
                    ))
                  : null}
              </Button.Group>
            </Col>
          </Row>
          <Col>
            {variObj
              ? variObj.classNames.map((className) => (
                  <Button
                    icon
                    circular
                    className={color === className ? "grow" : ""}
                    onClick={() => setColor(className)}
                  >
                    <Icon name="circle" className={className} />
                  </Button>
                ))
              : null}
          </Col>
        </div>
      </figure>
      <Container>
        <Col>
          <form
            className="justify-content-center"
            onSubmit={(e) => submitAddCart(e)}
          >
            <div className="form-group prod-form">
              <button type="submit" className="btn btn-pinkish">
                Add to cart
              </button>
            </div>
          </form>
        </Col>
      </Container>
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.object,
  addToCart: PropTypes.func,
  setCartNotification: PropTypes.func,
};

export default Product;
