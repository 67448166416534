import React from "react";
import ReactPlayer from "react-player";
import { Row, Container } from "reactstrap"
import { Helmet } from 'react-helmet'

/**
 * Music page componet, contains embedded iframes from youtube and Spotify.
 * Year: 2020
 * Author: Dillan Simpson
 */
const Music = () => {
  return (
    <Container>
      <Helmet>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9714476978963581"
          crossorigin="anonymous"></script>
        <title>TTR Music</title>
        <meta name="description" content="The Third-Rates Music" />
      </Helmet>
      <Row>
        <div className="player-wrapper">
          {/*Middle Of The Road Music Video*/}
          <ReactPlayer
            className="react-player"
            width='100%'
            height='100%'
            url="https://www.youtube.com/watch?v=qJfgOfC3LiE" />
        </div>
      </Row>
    </Container>
  );
};

export default Music;