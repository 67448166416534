import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import Layout from "./components/Layout.js";
import TheBand from "./TheBand";
import Music from "./Music";
import MerchStore from "./components/MerchStore";
import Home from "./Home";
import TourDates from "./TourDates.js";
import Cart from "./components/cart/Cart";
import localstorage from "./services/localstorage";
import axios from "axios";
import storeConfig from "./config";
import Loader from "./components/utils/Loader";
import Order from "./components/order/Order";
import Product from "./components/Product";
import ProductSelfShip from "./components/ProductSelfShip";
import productsService from "./services/products";
import Notification from "./components/utils/Notification";
//import NotFound from './components/NotFound';
//import { Redirect } from "react-router-dom";
//import ReactPixel from 'react-facebook-pixel';

/**
 * Main component for the app, the components found
 * here will be displayed on each page of the app.
 * Year: 2020
 * Author: Dillan Simpson
 */
const App = () => {
  const [cart, setCart] = useState(localstorage.getCart());
  const [orders, setOrders] = useState(localstorage.getOrders());
  const [products, setProducts] = useState([]);
  const [ssProducts, setSsProducts] = useState([]);
  const [orderNotification, setOrderNotification] = useState([]);
  const [cartNotification, setCartNotification] = useState([]);
  const [connectionNotification, setConnectionNotification] = useState([]);
  const [storeInfo, setStoreInfo] = useState(storeConfig);
  const [ssSales, setSsSales] = useState(localstorage.getSsSales());

 // ReactPixel.pageView();

  // Init store information
  useEffect(() => {
    axios
      .get("/api/store")
      .then((response) =>
        setStoreInfo({
          ...response.data,
          ...storeConfig,
        })
      )
      .catch(() =>
        setConnectionNotification([
          "Sorry, there's a problem with the connection!",
          "alert-danger",
        ])
      );
  }, [storeInfo.brand]);

  // Init products
  useEffect(() => {
    productsService.getAll().then((prods) => setProducts(prods));
  }, []);

  // Init self shipped products
  useEffect(() => {
    productsService.getAllSS().then((ssProds) => setSsProducts(ssProds));
  }, []);

  const ssProductById = (id) =>
    ssProducts.find((ssProduct) => ssProduct._id === parseInt(id));

  const productById = (id) =>
    products.find((product) => product.sync_product.id === parseInt(id));

  const addToCart = (item) => {
    setCart(localstorage.addToCart(item, cart));
  };
  
  const removeFromCart = (index) =>
    setCart(localstorage.removeFromCart(index, cart));
  const clearCart = () => setCart(localstorage.clearCart());
  const addOrder = (id) => setOrders(localstorage.addOrder(id, orders));
  const addSsSale = (id) => setSsSales(localstorage.addSsSale(id, ssSales));

  const findSsSale = (id) => {
    const pfItemCount = cart.items.filter(item => item.id).length
    const ssItemCount = cart.items.filter(item => item._id).length
    if (pfItemCount > 0 && ssItemCount > 0)
    {
      const ssItem = cart.items.filter(item => item._id).shift()
      const ssSaleId = ssItem && ssSales.length > 0 ? ssSales[ssSales.length -1] : null
      return ssSaleId;
    }
    else if (ssItemCount > 0 && pfItemCount <= 0)
    {
      return id
    }
    else
    {
      return null
    }
  }

  return (
    <Router>
      <AnimatedSwitch
        atEnter={{ opacity: 0 }}
        atLeave={{ opacity: 0 }}
        atActive={{ opacity: 1 }}
        className="row switch-wrapper"
      >
        <Layout>
          <Notification
            notification={connectionNotification}
            setNotification={setConnectionNotification}
          />
          <Notification
            notification={orderNotification}
            setNotification={setOrderNotification}
          />
          <Notification
            notification={cartNotification}
            setNotification={setCartNotification}
          />
          <Route path="/" component={Home} exact />
          <Route path="/theBand" component={TheBand} exact />
          <Route path="/Music" component={Music} exact />
          <Route path="/TourDates" component={TourDates} exact />
          {/* <Route path="/Hook" component={Hook} exact /> */}
          {/* <Route path="*" component={NotFound} /> */}
          <Route
            exact
            path="/Store"
            render={() => (
              <MerchStore products={products} ssProducts={ssProducts} cart={localstorage.getCart()} />
            )}
          />
          <Route
            exact
            path="/Cart"
            render={() => (
              <Cart
                cart={localstorage.getCart()}
                removeFromCart={removeFromCart}
                storeInfo={storeConfig}
                clearCart={clearCart}
                addSsSale={addSsSale}
              />
            )}
          />
          <Route
            exact
            path="/product/:id"
            render={({ match }) =>
              productById(match.params.id) ? (
                <Product
                  product={productById(match.params.id)}
                  addToCart={addToCart}
                  setCartNotification={setCartNotification}
                />
              ) : (
                <Loader />
              )
            }
          />
          <Route
            exact
            path="/ProductSelfShip/:id"
            render={({ match }) =>
              ssProductById(match.params.id) ? (
                <ProductSelfShip
                  ssProduct={ssProductById(match.params.id)}
                  addToCart={addToCart}
                  ssProducts={ssProducts}
                  setCartNotification={setCartNotification}
                  cart={localstorage.getCart()}
                />
              ) : (
                <Loader />
              )
            }
          />
          <Route
            exact
            path="/order/:id"
            render={({ match }) => (
              <Order
                orderId={cart.items.filter(item => item.id).length > 0 ? parseInt(match.params.id) : null}
                saleId={findSsSale(match.params.id)}
                setOrderNotification={setOrderNotification}
                addOrder={addOrder}
                orders={orders}
                addSsSale={addSsSale}
                ssSales={ssSales}
                storeInfo={storeInfo}
              />
            )}
          />
        </Layout>
      </AnimatedSwitch>
    </Router>
  );
};

export default App;
