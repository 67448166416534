/**
 * This is the clientsided config that should hold some necessary information for the store.
 * Some of this information, like the store name is also fetched from the Printful API
 */
export default {
  name: 'The Third-Rates Store',
  companySuffix: 'TTR',
  description: 'The Third-Rates Clothing',
  brand: 'TTR',
  established: '2021',
  // Sandbox
  //paypalClientID: 'AboKraQDGtkbiHUsE4oy7Ay9kNCd6o10UkItEuNg4N2ygR0x1b5rqzbdHc1KD_eAa5qZk04gxbJgt-Rk'
  // Live
  paypalClientID: 'AZjthtHA92YuwLP3gEg75gMGyq0GUO8VaHHBVDYSojpcTddfwf18sxThWb2EXZ2VYp4POOPvR0_FTBZ8'
}