import $ from 'jquery'

/**
 * A helper class to translate the printful cost data and self ship cost data.
 * Year: 2022
 * Author: Dillan Simpson
 */
export default class Cost {
    /**
     * Constructor.
     * @param {object=} retailCost the reatail cost object to be translated.
     */
    constructor(pfCosts, ssCosts) {
        var retailCost = null
        if (!$.isEmptyObject(pfCosts) && !$.isEmptyObject(ssCosts))
        {
            this.subtotal = (parseFloat(pfCosts.subtotal) + (ssCosts.retail_price * ssCosts.quantity)).toFixed(2)
            this.discount = pfCosts.discount
            this.shipping = ssCosts.ss_shipping ? parseFloat(Number(ssCosts.ss_shipping) + Number(pfCosts.shipping)).toFixed(2) : pfCosts.shipping
            this.ss_shipping = ssCosts.ss_shipping ? ssCosts.ss_shipping : 0
            this.tax = pfCosts.tax 
            this.total = (parseFloat(pfCosts.total) + this.ss_shipping + (ssCosts.retail_price * ssCosts.quantity)).toFixed(2)
            this.currency = pfCosts.currency
        }
        else
        {
            retailCost = !$.isEmptyObject(pfCosts) ? pfCosts : ssCosts

            var currency = retailCost.currency

            this.selfShip = currency ? false : true
            this.subtotal = currency ? retailCost.subtotal : (retailCost.retail_price * retailCost.quantity)
            this.discount = currency ? retailCost.discount : 0
            this.ss_shipping = ssCosts ? ssCosts.ss_shipping : 0
            this.shipping = currency ? retailCost.shipping : this.ss_shipping
            this.tax = currency ? retailCost.tax : 0
            this.total = currency ? retailCost.total : parseFloat((retailCost.retail_price * retailCost.quantity) + this.ss_shipping).toFixed(2)
            this.currency = currency ? retailCost.currency : 'USD'
        }
    }

    /**
     * Helper method to determine if the order data contains a self ship data.
     * @param {object=} orderObj the combined order data
     * @returns a boolean
     */
    static isSS(costObj) {
        return costObj.currency ? false : true;
    }

    /**
     * Helper method to determine if the order data contains a printful data.
     * @param {object=} orderObj the combined order data
     * @returns a boolean
     */
    static isPrintful(costObj) {
        return costObj.currency ? true : false;
    }
}