import React from 'react'
import { PayPalButton } from 'react-paypal-button-v2'
import PropTypes from 'prop-types'
import currency from 'currency.js'
import axios from 'axios'
import { Alert } from 'reactstrap'

/**
 * The Payment component for the application. Handles and displays PayPal payments.
 * Year: 2021
 * Author: Dillan Simpson
 */
const Payment = ({ order, costs, ssSale, setLoading, setOrderNotification, setPaid, clientID }) => {

  /**
   * Used to determine what kind of order was placed.
   * Both Order Types: 'The Third-Rates Store Self Ship & PF'
   * Self Shipped Order: 'The Third-Rates Store Self Ship'
   * Printdful order: 'The Third-Rates Store'
   */
  var brandName = null
  if (ssSale._id && ssSale.ss_shipping > 0 && costs.shipping !== ssSale.ss_shipping) 
  {
    brandName = 'The Third-Rates Store Self Ship & PF'
  }
  else if (ssSale._id) 
  {
    brandName = 'The Third-Rates Store Self Ship'
  }
  else
  {
    brandName = 'The Third-Rates Store'
  }

  const paymentApproved = (data, actions) => {
    setLoading(true)
    actions.order.capture().then(details => {
      axios.post(`/api/confirm/${details.id}`).then(() => {
        if (details.error === 'INSTRUMENT_DECLINED') {
          return [<Alert>There was an issue processing you payment. Please check your credit card details and try again.</Alert>, actions.restart()]
        }
        else {
          if (costs.selfShip)
          {
            const ssCount = ssSale.quantity
            const prodDbId = ssSale.prodDbId
            axios.post(`/products/updateQuantity/${prodDbId}/${ssCount}/`).then((res) => {
              setPaid(true)
            }).catch(console.error)
          }
          setOrderNotification(['Your order has been confirmed. We\'re getting it ready for shipment now!', 'alert-success'])
          setPaid(true)
          setLoading(false)
        }
      })
    })
  }

  return (
    <div className="row">
      <div className="col-md-12">
        You can pay with a credit / debit card via PayPal.
        All our transactions are processed by PayPal and we never store any of your payment information.<br />
      </div>
      <div className="col-md-12">
        <PayPalButton
          options={{
            clientId: clientID,
            commit: true,
          }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  currency_code: costs.currency,
                  value: costs.total,
                  breakdown: {
                    item_total: {
                      currency_code: costs.currency,
                      value: costs.subtotal,
                    },
                    shipping: {
                      currency_code: costs.currency,
                      value: costs.shipping,
                    },
                    handling: {
                      currency_code: costs.currency,
                      value: (currency(costs.digitization).add(costs.additional_fee).add(costs.filfillment_fee).value),
                    },
                    tax_total: {
                      currency_code: costs.currency,
                      value: currency(costs.tax).value,
                    },
                    discount: {
                      currency_code: costs.currency,
                      value: costs.discount,
                    },
                  },
                },
                description: `Payment for your order ${order.id ? order.id : ssSale._id}`,
                custom_id: `${order.id ? order.id : ssSale._id}`,
              }],
              application_context: {
                shipping_preference: 'NO_SHIPPING',
                brand_name: brandName,
              },
            })
          }}
          onApprove={(data, actions) => paymentApproved(data, actions)}
        />
      </div>
    </div>
  )
}

Payment.propTypes = {
  order: PropTypes.object,
  costs: PropTypes.object,
  ssSale: PropTypes.object,
  setLoading: PropTypes.func,
  setOrderNotification: PropTypes.func,
  setPaid: PropTypes.func,
  clientID: PropTypes.string
}

export default Payment
