import TTRCasual2 from "./resources/images/TTRCasual2.jpg"
import ReactPlayer from "react-player";
import React from "react"
import { Container, Row, Col } from 'reactstrap'
import { Image } from 'semantic-ui-react'
//import EmailModal from "./components/modal/EmailModal"

/**
 * Home page componet, contains news feed.
 * Year: 2020
 * Author: Dillan Simpson
 */
const Home = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col className={'email-modal-button'}>
            <Image className="figure-img img-fluid img-blur-edge" size="massive" src={TTRCasual2} alt={"TTR Lounge"} />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              width='90%'
              height='80%'
              url="https://youtu.be/yUol1KtCSqM" />
          </div>
        </Row>
        <Col className={'email-modal-button'} >
          <h3>Contact us on our socials or at thethirdratesska@gmail.com </h3>
        </Col>
        {/* <Col className={'email-modal-button'}>
            <EmailModal/>
          </Col> */}
      </Container>
    </div>
  );
};

export default Home;
