import React from 'react'
import PropTypes from 'prop-types'
import currency from 'currency.js'
import Payment from './Payment'
import Cost from '../utils/Cost'
import $ from 'jquery'

/**
 * The Price Component. Used to display cost info for the order.
 * @param {Object} order the order object 
 * @param {Object} ssSale the self ship object 
 * @param {Function} setLoading function to set loder
 * @param {Function} setOrderNotification function to set order notification
 * @param {Boolean} paid indicator to check if payment went through
 * @param {Function} setPaid function to set if payment went through
 * @param {Object} storeInfo holds store information for printful
 * @returns Price component with order deatails
 */
const Price = ({ order, ssSale, setLoading, setOrderNotification, paid, setPaid, storeInfo }) => {
  // Sets cost for printful order
  const costs = order.retail_costs && order.retail_costs.total ? order.retail_costs : order.costs
  // Object to convert cost info from both order types
  let costObj = {}
  
  if (!$.isEmptyObject(costs) && !$.isEmptyObject(ssSale))
  {
    costObj = new Cost(costs, ssSale)
  }
  else if (!$.isEmptyObject(costs))
  {
    costObj = new Cost(costs, null)
  }
  else if (!$.isEmptyObject(ssSale))
  {
    costObj = new Cost(null, ssSale)
  }

  return (
    <div className="card card-outline-secondary">
      <div className="card-body">
        <div className="row justify-content-between">
          <div className="col-4">
            <b>Price</b>
          </div>
          <div className="col-4 text-right">
            <b>$ USD</b>
          </div>
        </div>
        {
          order.id ?
            order.items.map((item) =>
              <div key={item.name} className="row justify-content-between">
                <div className="col-6">
                  {item.name} ({item.quantity})
                </div>
                <div className="col-4 text-right">
                  <div>${item.retail_price || item.price}</div>
                </div>
              </div>,
            )
            :
            <div></div>
        }
        {
          ssSale._id ?
            <div key={ssSale.productName} className="row justify-content-between">
              <div className="col-6">
                {ssSale.productName} ({ssSale.quantity})
              </div>
              <div className="col-4 text-right">
                <div>{currency(ssSale.retail_price * ssSale.quantity).format(true)}</div>
              </div>
            </div>
          : 
            <div></div>
        }
        <div className="row justify-content-between">
          <div className="col-4">
            <b>Shipping</b>
          </div>
          <div className="col-4 text-right">
            {costObj.shipping === 0 ? 'Free' : currency(costObj.shipping).format(true) }
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-4">
            <b>Tax({costObj.tax}%)</b>
          </div>
          <div className="col-4 text-right">
            {currency(costObj.tax ? costObj.tax : 0).multiply(costObj.total).format(true)}
          </div>
        </div>
        <div className="row justify-content-between mb-4">
          <div className="col-4">
            <b>Total price</b>
          </div>
          <div className="col-4 text-right">
            <b>{currency(costObj.total).format(true)}</b>
          </div>
        </div>
        {
          !paid ?
            <Payment
              costs={costObj}
              order={order}
              ssSale = {ssSale}
              setLoading={setLoading}
              setOrderNotification={setOrderNotification}
              setPaid={setPaid}
              clientID={storeInfo.paypalClientID} /> :
            <div></div>
        }
      </div>
    </div>
  )
}

Price.propTypes = {
  order: PropTypes.object,
  ssSale: PropTypes.object,
  setLoading: PropTypes.func,
  setOrderNotification: PropTypes.func,
  paid: PropTypes.bool,
  setPaid: PropTypes.func,
  storeInfo: PropTypes.object
}

export default Price
