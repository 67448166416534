import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'semantic-ui-react'

/**
 * Bootstrap notification wrapper
 * @param {array} notification - A STATE tuple where the first element is the notification text,
 * and the second is the alert color class, see https://getbootstrap.com/docs/4.4/components/alerts/
 * @param {function} setNotification - Function for setting the notification state
 */
const Notification = ({ notification, setNotification }) => {
  if (notification.length === 2) {
    return (
      <div id="cartNotification" className={`alert ${notification[1]} alert-dismissible fade show`} role="alert">
        {notification[0]}
        <Button icon onClick={() => setNotification([])}>
          <Icon name='window close outline' color='red' />
        </Button>
      </div>
    )
  } else {
    return (<div></div>)
  }
}

Notification.propTypes = {
  notification: PropTypes.array,
  setNotification: PropTypes.func,
}

export default Notification