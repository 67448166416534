import React, { useEffect, useState } from "react";
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBContainer,
} from "mdbreact";
import PropTypes from "prop-types";

// Photos to load for carousel
import Beanie1 from "../../resources/images/BMPhotos/ttr_bm_beanie-3.jpg";
import Beanie2 from "../../resources/images/BMPhotos/ttr_bm_beanie-5.jpg";
import Beanie3 from "../../resources/images/BMPhotos/ttr_bm_beanie-4.jpg";
import Beanie4 from "../../resources/images/BMPhotos/pf-ttr_bm_beanie-1.png";
import Beanie5 from "../../resources/images/BMPhotos/pf-ttr_bm_beanie-2.png";

import DMTshirt1 from "../../resources/images/BMPhotos/ttr_bm_tshirt-7.jpg";
import DMTshirt2 from "../../resources/images/BMPhotos/ttr_bm_tshirt-11.jpg";
import DMTshirt3 from "../../resources/images/BMPhotos/ttr_bm_tshirt-10.jpg";
import DMTshirt4 from "../../resources/images/BMPhotos/pf-ttr_bm_tshirt-1.png";
import DMTshirt5 from "../../resources/images/BMPhotos/pf-ttr_bm_tshirt-3.png";

import DMHoodie1 from "../../resources/images/BMPhotos/ttr_bm_hoodie-5.jpg";
import DMHoodie2 from "../../resources/images/BMPhotos/ttr_bm_hoodie-3.jpg";
import DMHoodie3 from "../../resources/images/BMPhotos/ttr_bm_hoodie-8.jpg";
import DMHoodie4 from "../../resources/images/BMPhotos/pf_ttr_bm_hoodie-1.png";
import DMHoodie5 from "../../resources/images/BMPhotos/pf_ttr_bm_hoodie-2.png";

import DMTLongSleeve1 from "../../resources/images/BMPhotos/ttr_bm_longsleeve-1.jpg";
import DMTLongSleeve2 from "../../resources/images/BMPhotos/pf-ttr_bm_longsleeve-4.png";
import DMTLongSleeve3 from "../../resources/images/BMPhotos/pf-ttr_bm_longsleeve-2.png";
import DMTLongSleeve4 from "../../resources/images/BMPhotos/pf-ttr_bm_longsleeve-1.png";
import DMTLongSleeve5 from "../../resources/images/BMPhotos/pf-ttr_bm_longsleeve-3.png";

/**
 * A component to display a carosuel of images for each product.
 * Year: 2023
 * Author: Dillan Simpson
 */

// Map of images to display
const beanieData = [
  { img: Beanie1, title: "Beanie1", prodId: 324500935 },
  { img: Beanie2, title: "Beanie2", prodId: 324500935 },
  { img: Beanie3, title: "Beanie3", prodId: 324500935 },
  { img: Beanie4, title: "Beanie4", prodId: 324500935 },
  { img: Beanie5, title: "Beanie5", prodId: 324500935 }
];

const TshirtData = [
  { img: DMTshirt1, title: "DMTee1", prodId: 324501353 },
  { img: DMTshirt2, title: "DMTee2", prodId: 324501353 },
  { img: DMTshirt3, title: "DMTee3", prodId: 324501353 },
  { img: DMTshirt4, title: "DMTee4", prodId: 324501353 },
  { img: DMTshirt5, title: "DMTee4", prodId: 324501353 }
];

const hoodieData = [
  { img: DMHoodie1, title: "DMHoodie1", prodId: 324501180 },
  { img: DMHoodie2, title: "DMHoodie2", prodId: 324501180 },
  { img: DMHoodie3, title: "DMHoodie3", prodId: 324501180 },
  { img: DMHoodie4, title: "DMHoodie4", prodId: 324501180 },
  { img: DMHoodie5, title: "DMHoodie5", prodId: 324501180 }
];

const longSleeveData = [
  { img: DMTLongSleeve1, title: "DMLong1", prodId: 324501256 },
  { img: DMTLongSleeve2, title: "DMLong2", prodId: 324501256 },
  { img: DMTLongSleeve3, title: "DMLong3", prodId: 324501256 },
  { img: DMTLongSleeve4, title: "DMLong4", prodId: 324501256 },
  { img: DMTLongSleeve5, title: "DMLong5", prodId: 324501256 }
];

const CarouselPage = ({ productId }) => {
  const [arr, setArr] = useState([]);

  useEffect(() => {
    if (beanieData[0].prodId === productId) {
      setArr(beanieData);
    } else if (TshirtData[0].prodId === productId) {
      setArr(TshirtData);
    } else if (hoodieData[0].prodId === productId) {
      setArr(hoodieData);
    } else if (longSleeveData[0].prodId === productId) {
      setArr(longSleeveData);
    }
  }, [productId]);

  return (
    <MDBContainer>
      <MDBCarousel
        activeItem={1}
        length={5}
        showControls={true}
        showIndicators={false}
        className="z-depth-1"
        interval={false}
      >
        {arr != null && arr.length > 0 ? (
          <MDBCarouselInner className="img-ratio">
            <MDBCarouselItem itemId="1">
              <MDBView>
                <img
                  className="d-block w-100"
                  src={arr[0].img}
                  alt="First slide"
                />
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="2">
              <MDBView>
                <img
                  className="d-block w-100"
                  src={arr[1].img}
                  alt="Second slide"
                />
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="3">
              <MDBView>
                <img
                  className="d-block w-100"
                  src={arr[2].img}
                  alt="Third slide"
                />
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="4">
              <MDBView>
                <img
                  className="d-block w-100"
                  src={arr[3].img}
                  alt="Fourth slide"
                />
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="5">
              <MDBView>
                <img
                  className="d-block w-100"
                  src={arr[4].img}
                  alt="Fifth slide"
                />
              </MDBView>
            </MDBCarouselItem>
          </MDBCarouselInner>
        ) : null}
      </MDBCarousel>
    </MDBContainer>
  );
};

CarouselPage.propTypes = {
  productId: PropTypes.number,
};

export default CarouselPage;
