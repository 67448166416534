import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import PropTypes from 'prop-types'
import currency from 'currency.js'
import { Helmet } from 'react-helmet'
import OrderCombine from '../utils/OrderCombine'
//import ReactPixel from 'react-facebook-pixel';

/**
 * The Cart component for the application. Cart page where customers can remove items, type in shipping details.
 * Year: 2021
 * Author: Dillan Simpson
 */
const Cart = ({ cart, removeFromCart, history, addSsSale }) => {
  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(undefined)
  const [continuing, setContinuing] = useState(false)
  const [shipping, setShipping] = useState(false)
  const [isSsProduct, setIsSsProduct] = useState(false)
  const [sSProduct, setSsProduct] = useState(undefined)
  
  useEffect(() => {
    axios.get('/api/countries')
      .then((response) => setCountries(response.data))
      .catch(console.error)
  }, [])

  useEffect(() => {
    cart.items.length === 0 ? setShipping(false) : setShipping(true)
    const ssFound = cart.items.find((product) => product.productId === 1112)
    if (ssFound != null) {
      setIsSsProduct(true)
    }
    setSsProduct(ssFound)
  }, [cart.items]);

  const setIsSsProd = (productId) => {
    if (productId === 1112) {
      setIsSsProduct(false);
    }
  }

  const submitInfo = (event) => {
    event.preventDefault()
    if (!selectedCountry) return

    const form = event.target
    
    const firstName = form.firstName.value
    const lastName = form.lastName.value
    const email = form.email.value
    const phone = form.phone.value
    const address = form.address.value
    const secondAddress = form.secondAddress.value
    const country = form.country.value
    const city = form.city.value
    const zip = form.zip.value

    const ssItems = cart.items.filter(item => item.productId === 1112)
    const ssCount = ssItems.length

    const productId = isSsProduct ? sSProduct.productId : null
    const prodDbId = isSsProduct ? sSProduct._id : null
    const productName = isSsProduct ? sSProduct.name : null
    const description = isSsProduct ? sSProduct.description : null
    const retail_price = isSsProduct ? sSProduct.retail_price : null
    const ss_shipping = isSsProduct ? sSProduct.ss_shipping : null
    const quantity = ssCount

    let body = {
        prodDbId,
        firstName,
        lastName,
        email,
        phone,
        address,
        secondAddress,
        country,
        city,
        zip,
        cart,
        productId,
        productName,
        description,
        retail_price,
        ss_shipping,
        quantity
      }

      if (form.state) {
        body.state = form.state.value
      }

    let orderCombine = new OrderCombine(body)
    let ssBody = orderCombine.toSsSale(body);
    let pfBody = orderCombine.toPfOrder(body);

    if (cart.items)
    {
      setContinuing(true)
    }

    let isPf = cart.items.filter(item => item.id).length > 0 ? true : false
    
    if (isSsProduct) {
      if (!isPf)
      {
        axios.post('/sales/add', ssBody)
          .then((response) => {
             const ssOrder = response.data
             history.push(`/order/${ssOrder}`)
          }).catch((error) => {
            console.error(error)
          })
      }
      else
      {
        // Add ssSale so we can grab id for Order page
        axios.post('/sales/add', ssBody)
          .then((response) => {
            const ssOrder = response.data
            addSsSale(ssOrder)
          }).catch((error) => {
            console.error(error)
          })
      }
    }

    // Only printful
    if (!isSsProduct)
    {
      axios.post('/api/orders', pfBody)
        .then((response) => {
          const order = response.data
          history.push(`/order/${order}`)
        }).catch((error) => {
          console.error(error)
        })
    }
    else if (isSsProduct && isPf)
    {
      // TODO figure out how to combine ids
      axios.post('/api/orders', pfBody)
        .then((response) => {
          const order = response.data
          history.push(`/order/${order}`)
        }).catch((error) => {
          console.error(error)
        })
    }
  }

  /**
   * Calculate the subtotal
   */
  const subtotal = cart.items.reduce((accum, current) => accum.add(current.retail_price), currency(0))
  return (
    <div className="row">
      <Helmet>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9714476978963581"
          crossorigin="anonymous"></script>
        <title>TTR Cart</title>
        <meta name="description" content="The Third-Rates Merch Store Cart" />
      </Helmet>
      <div className="row">
        <section className="col-sm p-2">
          <div className="row">
            <div className="col">
              <div className="card card-outline-secondary mb-3">
                <div className="card-body container">
                  <div className="row justify-content-between mb-2">
                    <div className="col-6"><b>Item</b></div>
                    <div className="col-6 text-right"><b>Price (USD)</b></div>
                  </div>
                  <div>
                    {
                      cart.items.length > 0 ?
                        <div>
                          {
                            cart.items.map((product, index) => {
                              return (
                                <div className="row justify-content-between" key={`${product.idSize}-${index}`}>
                                  <div className="col-6 mb-1">
                                    <button className="btn btn-remove btn-danger" onClick={() => { removeFromCart(index); setIsSsProd(product.productId) }}>&times;</button>&nbsp;
                                    {product.name}
                                  </div>
                                  <div className="mb-1 col-6 text-right">{currency(product.retail_price).format(true)}</div>
                                </div>
                              )
                            })
                          }
                        </div> :
                        <div className="mb-2">Shopping cart empty</div>
                    }
                  </div>
                  <div className="row justify-content-between">
                    <div className="col-6"><b>Total</b></div>
                    <div className="col-6 text-right" id="cartSummaryTotal">{subtotal.format(true)}</div>
                  </div>
                  <div className="row justify-content-between">
                    <div className="col-12">{<span>Shipping costs will be calculated after you submit your information.</span>}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {shipping ?
          <section className="col-sm p-2">
            <div className="row">
              <div className="col">
                <div className="card card-outline-secondary mb-3">
                  <div className="card-body">
                    <h3>Details</h3>
                    <small className="text-muted">You can not change these after fulfillment, make sure they are correct!</small>
                    <form onSubmit={(e) => submitInfo(e)}>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputFirstName" className="col-form-label">First Name</label>
                          <input name="firstName" type="text" className="form-control" id="inputFirstName" placeholder="First Name" required />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputLastName" className="col-form-label">Last Name</label>
                          <input name="lastName" type="text" className="form-control" id="inputLastName" placeholder="Last Name" required />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputEmail" className="col-form-label">Email</label>
                        <input name="email" type="email" className="form-control" id="inputEmail" placeholder="your@email.com" required />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputPhone" className="col-form-label">Phone Number(Including your country <a className="phone-anchor" target="_blank" href="https://en.wikipedia.org/wiki/List_of_country_calling_codes" rel="noopener noreferrer"><strong>calling code</strong></a>)</label>
                        <input name="phone" type="phone" className="form-control" id="inputPhone" placeholder="" required />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputAddress" className="col-form-label">Address</label>
                        <input name="address" type="text" className="form-control" id="inputAddress" placeholder="1234 Main St" required />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputAddress2" className="col-form-label">Address 2 (optional)</label>
                        <input name="secondAddress" type="text" className="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor" />
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputCountry" className="col-form-label">Country</label>
                          {
                            isSsProduct && countries ?
                              <select name="country" value={selectedCountry} onChange={(event) => setSelectedCountry(event.target.value)} id="inputCountry" className="form-select" required>
                                <option value="" selected disabled hidden>Select Country</option>
                                {
                                  countries.filter(country => country.code === "US").sort((first, second) => {
                                    return first.code > second.code ? -1 : 1;
                                  }).map((country) =>
                                    <option key={country.code} value={country.code}>{country.name}</option>,
                                  )
                                }
                              </select>
                              :
                              <select name="country" value={selectedCountry} onChange={(event) => setSelectedCountry(event.target.value)} id="inputCountry" className="form-select" required>
                                {
                                  countries.sort((a, b) => a.code > b.code ? 1 : -1).map((country) =>
                                    <option key={country.code} value={country.code}>{country.name}</option>,
                                  )
                                }
                              </select>
                          }
                        </div>
                        {
                          selectedCountry && countries.find((country) => country.code === selectedCountry).states ?
                            <div className="form-group col-md-6">
                              <label htmlFor="inputState" className="col-form-label">State</label>
                              <select name="state" id="inputState" className="form-select" required>
                                {
                                  countries
                                    .find((country) => country.code === selectedCountry).states
                                    .map((state) =>
                                      <option key={state.code} value={state.code}>{state.name}</option>,
                                    )
                                }
                              </select>
                            </div> :
                            <div></div>
                        }
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-7">
                          <label htmlFor="inputCity" className="col-form-label">City</label>
                          <input name="city" type="text" className="form-control" id="inputCity" required />
                        </div>
                        <div className="form-group col-md-5">
                          <label htmlFor="inputZip" className="col-form-label">Zip</label>
                          <input name="zip" type="text" className="form-control" id="inputZip" required />
                        </div>
                      </div>
                      {
                        continuing ? <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div> : <button type="submit" className="btn btn-pinkish mt-3">Continue to payment</button>
                      }
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          : null}
      </div>
    </div>
  )
}

Cart.propTypes = {
  cart: PropTypes.object,
  removeFromCart: PropTypes.func,
  history: PropTypes.object,
  storeInfo: PropTypes.object,
  clearCart: PropTypes.func,
  addSsSale: PropTypes.func
}

export default withRouter(Cart)