import React from "react";
import ImgGrid from "./components/Grid.js";

/**
 * The Band componet for the application.
 * Year: 2020
 * Author: Dillan Simpson
 */
const TheBand = () => {
  return <ImgGrid></ImgGrid>;
};

export default TheBand;
