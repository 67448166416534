import axios from 'axios'
const baseUrl = '/api/products'

const getAll = () => {
  const request = axios.get(baseUrl)
  return request.then((response) => response.data).catch(console.error)
}

const getAllSS = () => {
  const request = axios.get('/products/')
  return request.then((response) => response.data).catch(console.error)
}

const getSsSaleById = (id) => {
  const request = axios.get(`/sales/${id}/`)
  return request.then((response) => response.data).catch(console.error)
}

export default { getAll, getAllSS, getSsSaleById }