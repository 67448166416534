const ProductVariants = {
	"products": [
	// Autumn Long Sleeve
	{
		"productId": 324501256,
		"variants": [{
			"productId": 324501256,
			"id": 4110499504,
			"size": "S",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal Long Sleeve S",
			"image": null,
			"variantId": 3456,
			"sizes": ["S", "M", "L", "XL", "2XL"],
			"classNames": []
		},
		{
			"productId": 324501256,
			"id": 4110499505,
			"size": "M",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal Long Sleeve M",
			"image": null,
			"variantId": 3457,
			"sizes": ["S", "M", "L", "XL", "2XL"],
			"classNames": []
		},
		{
			"productId": 324501256,
			"id": 4110499506,
			"size": "L",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal Long Sleeve L",
			"image": null,
			"variantId": 3458,
			"sizes": ["S", "M", "L", "XL", "2XL"],
			"classNames": []
		},
		{
			"productId": 324501256,
			"id": 4110499507,
			"size": "XL",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal Long Sleeve XL",
			"image": null,
			"variantId": 3459,
			"sizes": ["S", "M", "L", "XL", "2XL"],
			"classNames": []
		},
		{
			"productId": 324501256,
			"id": 4110499509,
			"size": "2XL",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal Long Sleeve 2XL",
			"image": null,
			"variantId": 3460,
			"sizes": ["S", "M", "L", "XL", "2XL"],
			"classNames": []
		}
		]
	},
	// Autumn T-Shirts
	{
		"productId": 324501353,
		"variants": [{
			"productId": 324501353,
			"id": 4110501031,
			"size": "S",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal T-Shirt S",
			"image": null,
			"variantId": 474,
			"sizes": ["S", "M", "L", "XL", "2XL"],
			"classNames": []
		},
		{
			"productId": 324501353,
			"id": 4110501032,
			"size": "M",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal T-Shirt M",
			"image": null,
			"variantId": 505,
			"sizes": ["S", "M", "L", "XL", "2XL"],
			"classNames": []
		},
		{
			"productId": 324501353,
			"id": 4110501033,
			"size": "L",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal T-Shirt L",
			"image": null,
			"variantId": 536,
			"sizes": ["S", "M", "L", "XL", "2XL"],
			"classNames": []
		},
		{
			"productId": 324501353,
			"id": 4110501034,
			"size": "XL",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal T-Shirt XL",
			"image": null,
			"variantId": 567,
			"sizes": ["S", "M", "L", "XL", "2XL"],
			"classNames": []
		},
		{
			"productId": 324501353,
			"id": 4110501035,
			"size": "2XL",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal T-Shirt 2XL",
			"image": null,
			"variantId": 598,
			"sizes": ["S", "M", "L", "XL", "2XL"],
			"classNames": []
		}
		]
	},
	// Autumn Hoodie
	{
		"productId": 324501180,
		"variants": [{
			"productId": 324501180,
			"id": 4110498264,
			"size": "S",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal Hoodie S",
			"image": null,
			"variantId": 5530,
			"sizes": ["S", "M", "L", "XL", "2XL"],
			"classNames": []
		},
		{
			"productId": 324501180,
			"id": 4110498265,
			"size": "M",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal Hoodie M",
			"image": null,
			"variantId": 5531,
			"sizes": ["S", "M", "L", "XL", "2XL"],
			"classNames": []
		},
		{
			"productId": 324501180,
			"id": 4110498266,
			"size": "L",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal Hoodie L",
			"image": null,
			"variantId": 5532,
			"sizes": ["S", "M", "L", "XL", "2XL"],
			"classNames": []
		},
		{
			"productId": 324501180,
			"id": 4110498267,
			"size": "XL",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal Hoodie XL",
			"image": null,
			"variantId": 5533,
			"sizes": ["S", "M", "L", "XL", "2XL"],
			"classNames": []
		},
		{
			"productId": 324501180,
			"id": 4110498268,
			"size": "2XL",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal Hoodie 2XL",
			"image": null,
			"variantId": 5534,
			"sizes": ["S", "M", "L", "XL", "2XL"],
			"classNames": []
		}
		]
	},
	//Autumn Beanie
	{
		"productId": 324500935,
		"variants": [{
			"productId": 324500935,
			"id": 4110496915,
			"size": "",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal Beanie",
			"image": null,
			"variantId": 4522,
			"sizes": [],
			"classNames": [],
			"variImages": []
		}]
	},
	//Autumn Mug
	{
		"productId": 324500815,
		"variants": [{
			"productId": 324500815,
			"id": 4110495004,
			"size": "",
			"color": "",
			"className": "",
			"name": "The Third-Rates Black Metal Mug",
			"image": null,
			"variantId": 9323,
			"sizes": [],
			"classNames": [],
			"variImages": []
		}]
	},

	// TTR Stickers
	{
		"productId": 237724159,
		"variants": [{
			"productId": 237724159,
			"id": 2741066423,
			"size": "3x3",
			"color": "",
			"className": "",
			"name": "TTR Sticker 3X3",
			"image": null,
			"variantId": 10163,
			"sizes": ["3x3", "4x4", "5.5x5.5"],
			"classNames": [],
			"variImages": []
		},
		{
			"productId": 237724159,
			"id": 2741066425,
			"size": "4x4",
			"color": "",
			"className": "",
			"name": "TTR Sticker 4x4",
			"image": null,
			"variantId": 10164,
			"sizes": ["3x3", "4x4", "5.5x5.5"],
			"classNames": [],
			"variImages": []
		},
		{
			"productId": 237724159,
			"id": 2741066426,
			"size": "5.5x5.5",
			"color": "",
			"className": "",
			"name": "TTR Sticker 5.5x5.5",
			"image": null,
			"variantId": 10165,
			"sizes": ["3x3", "4x4", "5.5x5.5"],
			"classNames": [],
			"variImages": []
		},
		]
	},
	// SWS Sticker
	{
		"productId": 237724191,
		"variants": [{
			"productId": 237724191,
			"id": 2741066960,
			"size": "3x3",
			"color": "",
			"className": "",
			"name": "SWS Sticker 3X3",
			"image": null,
			"variantId": 10163,
			"sizes": ["3x3", "4x4", "5.5x5.5"],
			"classNames": [],
			"variImages": []
		},
		{
			"productId": 237724191,
			"id": 2741066961,
			"size": "4x4",
			"color": "",
			"className": "",
			"name": "SWS Sticker 4x4",
			"image": null,
			"variantId": 10164,
			"sizes": ["3x3", "4x4", "5.5x5.5"],
			"classNames": [],
			"variImages": []
		},
		{
			"productId": 237724191,
			"id": 2741066962,
			"size": "5.5x5.5",
			"color": "",
			"className": "",
			"name": "SWS Sticker 5.5x5.5",
			"image": null,
			"variantId": 10165,
			"sizes": ["3x3", "4x4", "5.5x5.5"],
			"classNames": [],
			"variImages": []
		},
		]
	}
	]
}

export default ProductVariants