import 'semantic-ui-css/semantic.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import './sass/custom.scss'
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ReactPixel from 'react-facebook-pixel';

//import reportWebVitals from "../test/reportWebVitals";
import { Context as ResponsiveContext } from 'react-responsive'
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init('667137378301893', options);
ReactPixel.pageView();
ReactDOM.render(
  <ResponsiveContext.Provider value={{ width: 500 }}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ResponsiveContext.Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
